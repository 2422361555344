import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
    loading(event){
        let uuid = event.target.href.split('uuid=')[1]        
        let html = `
            <div id="${uuid}">
                <div class="alert alert-warning my-6" role="alert">
                    <div class="flex items-start justify-start">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" role="img" aria-labelledby="aqvo3y7x8q8o5qunm0hkhegx9j7unahy" class="animate-spin inline-block h-4 w-4 mr-2"><title id="aqvo3y7x8q8o5qunm0hkhegx9j7unahy">Icons/spinner</title>
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Processing...</span>
                    </div>
                </div>
            </div>`
        document.querySelector('div[id="'+uuid+'"]').outerHTML = html
    }
}