// Reconnect ActionCable after switching accounts

import { Controller } from "@hotwired/stimulus"
import { Chart } from "frappe-charts"
import consumer from "../channels/consumer"

export default class extends Controller {

  static targets = ["chart"]

  connect(){    
    const charts = this.chartTargets
    charts.forEach( chart_container => {
      let labels = JSON.parse(chart_container.dataset.chartLabels)
      let datasets = []
      for (const [key, obj] of Object.entries(JSON.parse(chart_container.dataset.chartData))) {
        if(obj.data != null){
          let values = labels.map(x => obj.data[x] == undefined ? 0 : obj.data[x])
          datasets.push({
            name: obj.name,
            values: values,
            chartType: obj.type
          })
        };
      }

      let type = datasets.length >  1 ? 'axis-mixed' : datasets[0].chartType      

      let inverse = JSON.parse(this.element.dataset.inverse || false)

      let newChart = new Chart(chart_container, {  // or a DOM element,
                                                // new Chart() in case of ES6 module with above usage
        title: chart_container.dataset.title,
        data: {
          labels: labels,
          datasets: datasets
        },
        type: type, // or 'bar', 'line', 'scatter', 'pie', 'percentage'
        height: 400,
        axisOptions: {
          xAxisMode: 'tick',
          yIsSeries: inverse ? 'reverse' : 'normal'
        },
        barOptions: {
          spaceRatio: 0.3 // default: 1
        },
        colors: ['#ea580c', '#facc15','#16a34a', '#2563eb', '#6d28d9','#db2777', '#dc2626', '#fb923c','#4ade80','#38bdf8', '#a78bfa', '#ec4899']
      });
    })

  }
}
