import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"//

export default class extends Controller {

  change(event){
    let attribute = event.target.selectedOptions[0].value;
    let target = event.target.parentElement.nextElementSibling.id;
    console.log(target);
    console.log(attribute);

    get(`/contact_attributes/operators?attribute_id=${attribute}&target=${target}`, {
      responseKind: "turbo-stream"
    })
  }
}
