import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {

  static values = { url: String, attributes: String, valueField: String, labelField: String, model: String, placeholder: String, maxItems: String, items: String, platform: String, excludePlatform: String}

  connect() {
    //this.select = new TomSelect(this.element)
    this.initTomSelect();
    console.log(this.excludePlatformValue)
  }

  disconnect() {
    this.select.destroy()
  }

   initTomSelect() {
    if (this.element) {
      let url = `${this.urlValue}.json`
      let attributes = JSON.parse(this.attributesValue)
      let model = this.modelValue
      let items = []
      if(this.itemsValue != ""){
        items = JSON.parse(this.itemsValue)
      }
      console.log(items)

      this.select = new TomSelect(this.element, {
        optionClass: ( model == "platform" || model == "channel" ) ? "search" : null,
        itemClass: model == "platform" ? "search" : null,
        plugins: (model == "platform" || model == "country") ? null : ['remove_button'],
        valueField: this.valueFieldValue,
        labelField: this.labelFieldValue,
        maxItems: parseInt(this.maxItemsValue),
        maxOptions: model == "country" ? null : 50,
        items: items,
        selectOnTab: true,
        placeholder: this.placeholderValue,
        closeAfterSelect: true,
        hidePlaceholder: this.maxItemsValue > 1 ? false : true,
        preload: true,
        create: false,
        openOnFocus: true,
        highlight: false,
        hideSelected: true,
        searchField: attributes,
        load: (search, callback) => {
          fetch(`${url}?q=${search}&exclude_platform=${this.excludePlatformValue}`)
            .then(response => response.json())
            .then(json => {
              // Clear previous options
              //this.select.clearOptions();
              //this.select.clear(); // If necessary, clear selected items
              
              // Populate with new options
              callback(json);  // Ensure the correct data is passed to TomSelect
            }).catch(() => {
              callback();
            });
        },
        render: {
          item: function (data, escape) {
            if(model == "channel"){
              console.log(data)
            return '<div class="pl-2 px-4 rounded space-x-2 flex items-center flex group text-gray-900 hover:bg-blue-700 hover:text-white relative cursor-default select-none">' +
              '<span class="font-semibold text-gray-900 group-hover:text-white capitalize">' + escape(data.provider) + '</span>' +
              '<span class="font-light text-gray-600 group-hover:text-white text-sm">' + escape(data.nickname) + '</span>' +
              '</div>';
            }
            else if (model == "review_profile"){
              console.log(data)
              return '<div class="py-2 px-4 h-8 justify-between rounded space-x-4 flex items-center flex group text-gray-900 hover:bg-blue-700 hover:text-white relative cursor-default select-none border-blue-200 bg-blue-700 text-blue-50 rounded-lg p-1 px-2">' +
              '<span class="font-semibold text-slate-100 group-hover:text-white">' + escape(data.profile_name) + ' on ' + escape(data.platform) + '</span>' +
              //'<span class="font-light text-gray-600 group-hover:text-white text-sm">' + ( data.review_profile_rating === null ? "" : `${escape(data.review_profile_rating.count)} reviews` ) + '</span>' +
              '</div>';
            }
            else if (model == "platform"){
              return '<div class="px-0 relative cursor-default select-none">' +
              '<span class="text-gray-900 capitalize">' + escape(data.platform_name) + '</span>' +
              '</div>'
            }
            else if (model == "country"){
              return '<div class="px-0 relative cursor-default select-none">' +
              `<span class="fi fi-${escape(data.country_code.toLowerCase())} mr-4"></span><span class="text-gray-900 capitalize">${escape(data.country_name)}</span>` +
              '</div>'
            }    
            else if (model == "country_flow"){
              return '<div>'+`<span>${escape(JSON.stringify(data))}</span>` + '</div>'
            }        
          },
          option: function (data, escape) {
            if(model == "channel"){
              console.log(data)
            return '<div class="py-2 px-4 h-12 rounded space-x-4 flex items-center flex group text-gray-900 hover:bg-blue-700 hover:text-white relative cursor-default select-none">' +
              '<span class="font-semibold text-gray-900 group-hover:text-white capitalize">' + escape(data.provider) + '</span>' +
              '<span class="font-light text-gray-600 group-hover:text-white text-sm">' + escape(data.nickname) + '</span>' +
              '</div>';
            }
            else if (model == "review_profile"){
              console.log(data)
              return '<div class="py-2 px-4 h-12 justify-between rounded space-x-4 flex items-center flex group text-gray-900 hover:bg-blue-700 hover:text-white relative cursor-default select-none ">' +
              '<span class="font-semibold text-gray-900 group-hover:text-white">' + escape(data.profile_name) + ' on ' + escape(data.platform) + '</span>' +
              //'<span class="font-light text-gray-600 group-hover:text-white text-sm">' + ( data.review_profile_rating === null ? "" : `${escape(data.review_profile_rating.count)} reviews` ) + '</span>' +
              '</div>';
            }
            else if (model == "platform"){
              return '<div class="leading-5 py-2 px-4 h-12 rounded space-x-4 flex items-center flex group text-gray-900 hover:bg-blue-700 hover:text-white relative cursor-default select-none">' +
              '<span class="font-semibold text-gray-900 group-hover:text-white capitalize">' + escape(data.platform_name) + '</span>' +
              '</div>'
            }
            else if (model == "country"){
              return `<div class="leading-5 py-2 px-4 h-12 rounded space-x-4 flex items-center flex group text-gray-900 hover:bg-blue-700 hover:text-white relative cursor-default select-none">`+
              `<span class="fi fi-${escape(data.country_code.toLowerCase())}"></span><span class="font-semibold text-gray-900 group-hover:text-white capitalize">${escape(data.country_name)}</span>` +
              `</div>`;
            }
            else if (model == "country_flow"){
              return `<div class="leading-5 py-2 px-4 h-12 rounded space-x-4 flex items-center flex group text-gray-900 hover:bg-blue-700 hover:text-white relative cursor-default select-none">`+
              `<span class="fi fi-${escape(data.country_code.toLowerCase())}"></span><span class="font-semibold text-gray-900 group-hover:text-white capitalize">${escape(data.country_name)}</span>` +
              `</div>`;
            } 
          }
        }
      })

    }
  }

}
