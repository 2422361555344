import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"//

export default class extends Controller {

    static values = {
        id: String
    }

    async purchase(event) {        
        console.log(this.idValue)
        const response = await post(`/exports/${this.idValue}/purchase`, {
            responseKind: "json"
        });

        if (response.ok) {
            const data = await response.json;
            window.location.href = data.session_url;
        } else {
            // Handle error here
            console.error("Error creating checkout session");
        }
    }
}