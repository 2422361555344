import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    beforeFetchRequest() {
        this.element.style.transition = "transform 0.5s ease-in-out, opacity 0.5s ease-in-out";
        this.element.classList.add("translate-x-full", "opacity-0");
      }
    
      frameLoad() {
        this.element.classList.remove("translate-x-full", "opacity-0");
        this.element.classList.add("translate-x-0", "opacity-100");
      }
}
