import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {

    static values = {items: String }

    connect() {
        //this.select = new TomSelect(this.element)
        this.initTomSelect();
    }

    disconnect() {
    this.select.destroy()
    }

    initTomSelect() {
        this.select = new TomSelect(this.element, {
            optionClass:  "" ,
            itemClass: "",
            plugins: ['remove_button'],
            valueField: 'id',
            labelField: 'name',
            maxItems: 10,
            maxOptions: 10,
            items: this.itemsValue != "" ? JSON.parse(this.itemsValue) : [],
            selectOnTab: true,
            placeholder: 'Add a tag',
            closeAfterSelect: true,
            hidePlaceholder: false,
            preload: 'focus',
            create: function(input, callback){
                fetch(`/tags.json`, {
                    method: 'POST',
                    body: JSON.stringify({tag: {name: input}}),
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
                    }
                })
                .then(response => response.json())
                .then(json => {
                    console.log(json);                    
                    callback(json);
                })
                .catch(() => {
                    callback();                  
                });
            },
            openOnFocus: true,
            highlight: false,
            hideSelected: true,
            searchField: 'name',
            load: (search, callback) => {
              fetch(`/tags.json?q=${search}`)
                .then(response => response.json())
                .then(json => {                    
                    callback(json);
                }).catch(() => {
                  callback();
                });
            },
            render: {
              item: function (item, escape) {                      
                return `<div class="pl-2 px-4 py-2 hover:gray-100 rounded space-x-2 flex items-center flex group text-gray-900 hover:bg-blue-700 hover:text-white relative cursor-default select-none">${escape(item.name)}</div>`;           
              },
              option: function (item, escape) {                     
                return `<div class="px-4 pl-2 py-2 hover:gray-100 relative cursor-default select-none">${escape(item.name)}</div>`;             
              }
            }
          })
    }
}