import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
    connect() {
        console.log("Connecting TomSelect");
        console.log(this.element)
        new TomSelect(this.element, {
            create: false,
            sortField: {
                field: "text",
                direction: "asc"
            }
        })
      }
}