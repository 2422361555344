import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["messages", "input", "form", "form-container"]

  connect() {
    this.element.style.overflow = 'hidden';    
    this.resize();
    this.scrollToBottom();    
    this.formTarget.addEventListener('turbo:submit-end', () => {     
      if(document.querySelector("div#placeholder") != null){
        document.querySelector("div#placeholder").remove()
      } 
      this.clearTextarea();
      this.scrollToBottom(); // Scroll to bottom after submitting the form
    });

    this.observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.addedNodes.length > 0) {
          const lastAddedNode = mutation.addedNodes[mutation.addedNodes.length - 1];
          if (lastAddedNode.nodeType === 1 && lastAddedNode.tagName === 'DIV') {
            this.scrollToBottom();
          }
        }
      });
    });

    this.observeMessages = () => {
      const container = document.getElementById('messages'); // Replace with your actual container element
      this.observer.observe(container, {
        childList: true,
        subtree: false
      });
    };    
    
    this.messagesTarget.addEventListener("scroll", this.loadMoreMessages.bind(this));
  }

  loadMoreMessages() {    
    console.log("scrolling")
    if (this.messagesTarget.scrollTop === 0) {    
      console.log("scrolling to top")  
      // If the user has scrolled to the top, load more messages      
      const oldestMessage = this.messagesTarget.querySelector(".chat-message:first-child");
      console.log(oldestMessage)
      if (oldestMessage) {
        this.lastLoadedTimestamp = oldestMessage.getAttribute("data-timestamp");
        console.log(this.lastLoadedTimestamp)
      }
      this.fetchMoreMessages(this.lastLoadedTimestamp);      
    }
  }
  
  fetchMoreMessages(lastTimestamp) {
    var id = this.messagesTarget.dataset["chatSessionId"];
    const url = `/chat_sessions/${id}/chat_messages/load_more?last_message_timestamp=${lastTimestamp}`;
    get(url, {
      responseKind: "turbo-stream"
    })
  }

  observeMessages() {
    if (this.hasMessagesTarget) {
      this.observer.observe(this.messagesTarget, { childList: true });
    }
  }

  disconnect() {
    this.observer.disconnect();
  }

  resize() {
    this.inputTarget.style.height = 'auto'; // Reset the height to auto
    this.inputTarget.style.height = `${this.inputTarget.scrollHeight}px`;
    const formHeight = this.formTarget.offsetHeight // Use offsetHeight to include padding and border
    const newMessagesHeight = `calc(100vh - ${formHeight}px - 9rem)`; // Adjust 3rem to account for any additional fixed elements like a header or footer    
    this.messagesTarget.style.height = newMessagesHeight;    
  }

  submitOnEnter(event) {    
    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      console.log(this.formTarget)
      event.preventDefault();
      event.stopImmediatePropagation()
      this.formTarget.requestSubmit()
      this.clearTextarea(); // Clear the textarea after form submission
    }    
  }

  clearTextarea() {
    this.inputTarget.value = '';
    this.element.value = '';
    this.resize(); // Adjust the size if needed
  }

  scrollToBottom() {    
    console.log("Scrolling")
    // Scroll the messages div to the bottom
    this.messagesTarget.scrollTop = this.messagesTarget.scrollHeight;    

    // Scroll the whole page to the bottom if needed
    window.scrollTo(0, document.body.scrollHeight);    
  }

}
