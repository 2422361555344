import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="file-upload"
export default class extends Controller {
  static targets = ["label", "dropzone", "icon", "loading", "segmentname"]

  connect() {
    console.log("Ready to import files")
  }

  update_import_field(event) {
    const fileInput = event.target
    const fileName = fileInput.files.length > 0 ? fileInput.files[0].name : "Upload a file"
    this.labelTarget.textContent = fileName
  }

  dragOver(event) {
    event.preventDefault()
    this.dropzoneTarget.classList.add("bg-gray-100")
    this.iconTarget.classList.add("animate-bounce", "scale-110")
  }

  dragLeave(event) {
    event.preventDefault()
    this.dropzoneTarget.classList.remove("bg-gray-100")
    this.iconTarget.classList.remove("animate-bounce", "scale-110")
  }

  drop(event) {
    event.preventDefault()
    this.dropzoneTarget.classList.remove("bg-gray-100")

    const file = event.dataTransfer.files[0]
    if (file) {      
      const fileInput = document.getElementById("file-upload")
      const dataTransfer = new DataTransfer()
      dataTransfer.items.add(file)
      fileInput.files = dataTransfer.files      
      const fileName = fileInput.files.length > 0 ? fileInput.files[0].name : "Upload a file"
      this.labelTarget.textContent = fileName
      this.iconTarget.classList.remove("animate-bounce", "scale-110")
    }
  }

  showLoading() {
    const loadingHtml = this.loadingTarget.innerHTML
    this.dropzoneTarget.innerHTML = loadingHtml
  }

  submitForm(event) {
    event.preventDefault()

    // Trigger showLoading method
    this.showLoading()

    // Submit the form
    const form = event.target.closest("form")
    form.requestSubmit()
  }
}
