import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect(){
    console.log("Tracking Controller Connected")

  }

  search(){
    dataLayer.push({
      'event':'search'
    })
  }

  search_result_click(){
    dataLayer.push({
      'event':'search_result_click'
    })
  }

  review_listing_creation(){

  }

  flow_creation(){

  }

  flow_activation(){

  }

  channel_creation(){

  }

  widget_creation(){

  }

  widget_share(){

  }

  review_export(){

  }

  billing_page(){

  }

}
