import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flash"
export default class extends Controller {
  connect() {
    this.open();  // Automatically open the flash when the controller connects
  }

  open() {
    // Add the class to show the flash
    this.element.classList.add("translate-x-0");
    this.element.classList.remove("translate-x-full");

    // Close the flash after 3 seconds
    setTimeout(() => {
      this.close();
    }, 3000);
  }

  close() {
    // Add the class to hide the flash
    this.element.classList.add("translate-x-full");
    this.element.classList.remove("translate-x-0");

    // Remove the flash from the DOM after 1 second to allow transition to complete
    setTimeout(() => {
      this.element.remove();
    }, 1000);
  }
}
