// app/javascript/controllers/accordeon_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "form"]  

  connect(){
    console.log(this.contentTarget);
    console.log(this.formTarget);
  }

  toggle() {
    this.contentTarget.classList.toggle('hidden');
    this.formTarget.classList.toggle('hidden');
  }

  close() {
    this.contentTarget.classList.remove('hidden');
    this.formTarget.classList.add('hidden');
  }
}
