import { Controller } from "@hotwired/stimulus";
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

export default class extends Controller {
  static targets = ["container"]

  connect() {
    console.log("Connected to swiper");
    this.swiper = new Swiper(this.containerTarget, {
      modules: [Navigation, Pagination],
      direction: "horizontal",
      loop: true,
      slidesPerView: 1,
      breakpoints:{
        991: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
      },
      navigation: {
        nextEl: '.swiper-next',
        prevEl: '.swiper-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
  }

  disconnect() {
    if (this.swiper) {
      this.swiper.destroy();
    }
  }
}
