import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("hello world")
  }

  toggle(event){
    event.preventDefault();
    console.log("clicked")
    event.currentTarget.querySelector("a").classList.toggle("selected")
  }

  disconnect() {
    this.select.destroy()
  }
}
