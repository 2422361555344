import { Controller } from "@hotwired/stimulus"
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default class extends Controller {
  static values = {
    labels: Array,
    datasets: Array
  }  

  connect() {
    console.log(this.datasetsValue)
    if(this.element.dataset.chartType == 'nps'){
      this.drawNPSChart();
    }
    else{
      this.drawLineChart();    
    }
  }

  drawNPSChart() {
    const ctx = this.element.querySelector('canvas').getContext('2d');
    console.log(this.chartData())
    this.chart = new Chart(ctx, {
      type: 'bar',
      data: this.NPSchartData(),
      options: this.npsChartOptions()
    });
  }

  drawLineChart() {    
    const ctx = this.element.querySelector('canvas').getContext('2d');
    console.log(this.element.dataset);
    this.chart = new Chart(ctx, {
      type: 'line',
      data: this.chartData(),      
      options: this.element.dataset.chartType === 'rankings' ? this.rankingChartOptions() : this.defaultChartOptions()      
    });
  }

  chartData() {
    return {
      labels: this.labelsValue,
      datasets: this.generateDatasets(this.datasetsValue)
    };
  }

  NPSchartData() {
    return {
      labels: this.labelsValue,
      datasets: this.generateNPSDatasets(this.datasetsValue)
    };
  }

  defaultChartOptions(){
    return {
      plugins: {
        legend: {
          labels: {
            usePointStyle: true // This makes the legend use the same style as the data points (e.g., circles)
          },
          // onClick: function(event, legendItem, legend){                           
          // },
          position: 'bottom',
          padding: 40,
        }
      },
      layout: {     
        padding: {
          top: 20,
          bottom: 20
        }   
      },
      scales: {
        y: {                
          grid: {
            display: false // This hides the grid lines on the y-axis
          },
          ticks: {
            display: true,
            padding: 20, // This adds space between the tick labels and the chart area
            stepSize: 1,
            callback: function(value) {
              return value % 1 === 0 ? value : Math.round(value, 0);
            }
            // Adjust padding on the y-axis                        
          }
        },
        x: {          
          title: {
            display: true,            
          },
          grid: {
            display: false // This hides the grid lines on the x-axis
          },
          ticks: {
            padding: 20, // This adds space between the tick labels and the chart area
            afterFit: function(scale) {
              scale.paddingTop = 20; // Adds padding at the top of the y-axis
              scale.paddingBottom = 20; // Adds padding at the bottom of the y-axis
            },
            display: true // This ensures the labels on the x-axis are still shown
          }
        }
      }
    }
  }

  npsChartOptions(){
    return{
      plugins: {
        legend: {
          labels: {
            usePointStyle: true // This makes the legend use the same style as the data points (e.g., circles)
          },
          // onClick: function(event, legendItem, legend){                           
          // },
          position: 'bottom',
          padding: 40,
        }
      },
      layout: {     
        padding: {
          top: 20,
          bottom: 20
        }   
      },
      scales: {
        y: {  // Primary Y-axis
            type: 'linear',
            display: true,
            position: 'left',
            grid: {
                display: false // This hides the grid lines on the y-axis
            },
            min: 0,
            max: 10,
            ticks: {
                display: true,
                padding: 20, // This adds space between the tick labels and the chart area
                stepSize: 5,                
                callback: function(value) {
                    return value % 1 === 0 ? value : Math.round(value, 0);
                }
            }
        },
        y1: {  // Secondary Y-axis
            type: 'linear',
            display: true,
            position: 'right',
            grid: {
                display: false // This hides the grid lines on the y-axis
            },
            min: -100,
            max: 100,
            ticks: {
                display: true,
                padding: 20, // This adds space between the tick labels and the chart area
                stepSize: 10,
                callback: function(value) {
                    return value % 1 === 0 ? value : Math.round(value, 0);
                }
            }
        },
        x: {          
            title: {
                display: true,            
            },
            grid: {
                display: false // This hides the grid lines on the x-axis
            },
            ticks: {
                padding: 20, // This adds space between the tick labels and the chart area
                afterFit: function(scale) {
                    scale.paddingTop = 20; // Adds padding at the top of the y-axis
                    scale.paddingBottom = 20; // Adds padding at the bottom of the y-axis
                },
                display: true // This ensures the labels on the x-axis are still shown
            }
        }
      },
    }
  }

  rankingChartOptions() {
    return {      
      plugins: {
        legend: {
          labels: {
            usePointStyle: true // This makes the legend use the same style as the data points (e.g., circles)
          },
          // onClick: function(event, legendItem, legend){                           
          // },
          position: 'bottom',
          padding: 40,
        }
      },
      layout: {     
        padding: {
          top: 20,
          bottom: 20
        }   
      },
      scales: {
        
        y: {
          reverse: true,
          min: 0.9,
          max: this.calculateMaxY(),
          grid: {
            display: false // This hides the grid lines on the y-axis
          },
          ticks: {
            display: true,
            padding: 20, // This adds space between the tick labels and the chart area
            stepSize: 1,
            callback: function(value) {
              return value % 1 === 0 ? value : Math.round(value, 0);
            }
            // Adjust padding on the y-axis                        
          }
        },
        x: {          
          title: {
            display: true,            
          },
          grid: {
            display: false // This hides the grid lines on the x-axis
          },
          ticks: {
            padding: 20, // This adds space between the tick labels and the chart area
            afterFit: function(scale) {
              scale.paddingTop = 20; // Adds padding at the top of the y-axis
              scale.paddingBottom = 20; // Adds padding at the bottom of the y-axis
            },
            display: true // This ensures the labels on the x-axis are still shown
          }
        }
      }
    };
  }

  updateMaxY(index) {
    
  }

  calculateMaxY() {
    const allRanks = this.datasetsValue.flatMap(dataset => dataset.data);
    const maxRank = Math.max(...allRanks);
    return maxRank > 10 ? maxRank + 0.1 : 10.1;
  }

  generateNPSDatasets(data){
    const COLORS = [ '#2563eb', '#16a34a',  '#facc15','#ea580c', '#6d28d9','#db2777', '#dc2626', '#fb923c','#4ade80','#38bdf8', '#a78bfa', '#ec4899']
    let datasets = [];
    for (let i = 0; i < data.length; i++) {
      datasets.push({
        label: data[i].label,
        data: data[i].data,
        borderColor: COLORS[i % COLORS.length],
        backgroundColor: COLORS[i % COLORS.length],
        fill: false,
        tension: 0.1,
        yAxisID: data[i].yAxisID || 'y',
        type: data[i].type || 'line'
      });
    }
    return datasets;
  }

  generateDatasets(data) {
    const COLORS = ['#ea580c', '#facc15','#16a34a', '#2563eb', '#6d28d9','#db2777', '#dc2626', '#fb923c','#4ade80','#38bdf8', '#a78bfa', '#ec4899']
    let datasets = [];
    for (let i = 0; i < data.length; i++) {
      datasets.push({
        label: data[i].label,
        data: data[i].data,
        borderColor: COLORS[i % COLORS.length],
        backgroundColor: COLORS[i % COLORS.length],
        fill: false,
        tension: 0.1,
        yAxisID: data[i].yAxisID || 'y',
        type: data[i].type || 'line'
      });
    }
    return datasets;
  }

  randomColor() {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgba(${r}, ${g}, ${b}, 0.5)`;
  }
}
