import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form"]

  connect() {
    this.formTarget.addEventListener("submit", this.handleSubmit.bind(this))
  }

  handleSubmit(event) {
    // Optionally, you can prevent default if you want to stop the form from submitting naturally
    
    this.closeModal();        
    }

  closeModal() {
    // Look for the closest modal/dialog element
    const modal = this.element.closest("[data-controller='modal']")
    
    if (modal) {
      const modalController = this.application.getControllerForElementAndIdentifier(modal, "modal")
      if (modalController) {
        modalController.close()
      }
    }
  }
}
