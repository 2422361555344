import { Controller } from "@hotwired/stimulus"

export default class extends Controller {    
  
    connect() {        
    }

    update_link_cta(event){        
        let btn = event.target.parentElement.parentElement.querySelector("a.subscribe");
        let url = new URL(btn.href);
        let params = url.searchParams;
        params.set("qty", event.target.value);
        url.search = params.toString();        
        btn.text = `Continue & pay $${(event.target.value)*event.target.dataset.unit_price}`
        btn.href = url.toString();
    }
    update_form_cta(event){        
        let current_qty = parseInt(event.target.dataset.currentQty)||0; 
        let btn = event.target.parentElement.parentElement.querySelector("input.subscribe");
        if (current_qty > event.target.value){
            btn.value = `Continue & save $${Math.abs(event.target.value - current_qty) * event.target.dataset.unitPrice}`;
        }
        else{
            btn.value = `Continue & pay $${(event.target.value - current_qty)*event.target.dataset.unitPrice}`
        }        
    }
} 