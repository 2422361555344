// app/javascript/controllers/toggle_buttons_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["hiddenField", "form"]

  connect() {
    this.updateButtonStyles();    
  }

  toggle(event) {
    event.preventDefault();
    const button = event.currentTarget;
    const buttonValue = button.getAttribute('data-value');
    const isCurrentlyActive = this.hiddenFieldTarget.value === buttonValue;

    // If the active button is clicked again, set the value to an empty string to deactivate it.
    this.hiddenFieldTarget.value = isCurrentlyActive ? '' : buttonValue;

    this.updateButtonStyles();
    this.formTarget.requestSubmit();
  }

  updateButtonStyles() {
    const currentValue = this.hiddenFieldTarget.value;

    this.element.querySelectorAll('.toggle-button').forEach((button) => {
      const buttonValue = button.getAttribute('data-value');
      const isActive = currentValue === buttonValue;
      const activeClasses = button.getAttribute('data-active').split(' ');
      const inactiveClasses = button.getAttribute('data-inactive').split(' ');

      if (isActive) {
        button.classList.add(...activeClasses);
        button.classList.remove(...inactiveClasses);
      } else {
        button.classList.remove(...activeClasses);
        button.classList.add(...inactiveClasses);
      }
    });
  }
}
