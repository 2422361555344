import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import { patch } from "@rails/request.js"

export default class extends Controller {

  static targets = ["dynamicPartial"]
  static values = {
    id: String,
    groupid: String
  }

  gate_change(event){
    let gate = event.target.dataset.value;
    if(gate == "true"){
      document.querySelector("#cutoff_container").classList.remove("hidden");
    }
    else{
      document.querySelector("#cutoff_container").classList.add("hidden");
    }
  }

  score_type_change(event){
    let score_type = event.target.selectedOptions[0].value;
    //Update the partial
    patch(`/review_campaigns/${event.target.form.dataset.reviewCampaignId}/score?score_type=${score_type}`, {
      responseKind: "turbo-stream"
    })    
    
    if (score_type == "1"){      
      //Update the cutoff select field
      let arr = []
      for(var i = 0; i <= 10; i++){
        arr.push(`<option ${i == 8 ? 'selected' : ''} value="${i}">${i}</option>`)
      }
      document.querySelector("#cutoff").innerHTML = arr.join("");
    }
    else if (score_type == "2"){      
      //Update the cutoff select field
      let arr = []
      for(var i = 1; i <= 5; i++){        
        arr.push(`<option ${i == 5 ? 'selected' : ''} value="${i}">${i}</option>`)
      }
      document.querySelector("#cutoff").innerHTML = arr.join("");      
    } 
    
    //Update product name in partial
    if (document.querySelector("#review_campaign_product_name").value != ""){
      document.querySelector(".product").innerText = document.querySelector("#review_campaign_product_name").value;
    }
    
  }

  product_name_change(event){
    document.querySelector(".product").innerText = event.target.value;
  }

  logic_change(event){
    console.log("logic change");
    console.log(event.target.selectedOptions[0].value);
    console.log(event.target.nextElementSibling);
    if (event.target.selectedOptions[0].value == "is empty" || event.target.selectedOptions[0].value == "is not empty"){
      event.target.nextElementSibling.classList.add("hidden");
    }
    else{
      event.target.nextElementSibling.classList.remove("hidden");
    }

  }

  change(event){
    let attribute = event.target.selectedOptions[0].value;
    let target = this.dynamicPartialTarget.id
    let id = this.idValue
    let group_id = this.groupidValue
    if (attribute == "0"){
      document.querySelector(`#review_listing_field_${id}`).classList.add("hidden");
      //document.querySelector(`#review_form_link_field_${id}`).classList.add("hidden");
      document.querySelector(`#cta_text_container_${id}`).classList.add("hidden");
    }
    else{
      document.querySelector(`#review_listing_field_${id}`).classList.remove("hidden");
      //document.querySelector(`#review_form_link_field_${id}`).classList.remove("hidden");
      document.querySelector(`#cta_text_container_${id}`).classList.remove("hidden");
    }    
    get(`/review_campaign_actions/fields?attribute=${attribute}&target=${target}&id=${id}&group_id=${group_id}`, {
      responseKind: "turbo-stream"
    })
  }
}
