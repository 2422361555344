import Flatpickr from 'stimulus-flatpickr';
import { Controller } from "@hotwired/stimulus";

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  static targets = ["input", "startDate", "endDate"];
  static values = { ranges: Array };

  connect() {
    // Global options
    this.config = {
      ...this.config,
      mode: 'range',
      dateFormat: 'd/m/Y',
      onChange: this.updateValue.bind(this)
    };

    // Always call super.connect()
    super.connect();
    this.defineRanges();
    this.calendarContainerTarget.classList.add("has-predefined_ranges");
    // Render date ranges
    this.renderRanges();
    
    console.log(this.fp);
        
    // Set initial value to the "Last 30 Days" range
    const last30DaysRange = this.ranges.find(range => range.name === "Last 30 days");
    if (last30DaysRange && this.inputTarget.value === "") {
      const startDate = this.parseDate(last30DaysRange.start_date);
      const endDate = this.parseDate(last30DaysRange.end_date);
      this.fp.setDate([startDate, endDate]); 
      this.inputTarget.value = last30DaysRange.name; 
    }
    
  }

  renderRanges() {
    // Use the previously defined ranges
    const container = document.createElement("div");
    container.setAttribute("data-controller", "date-range-select");
    container.classList.add("flex", "h-full", "flex-col", "predefined_ranges", "w-32", "py-2");
  
    this.ranges.forEach(range => {
      const rangeDiv = document.createElement("div");
      rangeDiv.className = 'flex px-2 py-2 rounded text-gray-900 hover:bg-blue-700 hover:text-blue-100 relative cursor-pointer select-none';
      rangeDiv.setAttribute('data-action', 'click->date-range-select#preselected');
      rangeDiv.setAttribute('data-start-date', range.start_date);
      rangeDiv.setAttribute('data-end-date', range.end_date);
      rangeDiv.innerText = range.name;
  
      // Append the range div to the container
      container.appendChild(rangeDiv);
    });
  
    // Insert the container into the calendar
    this.calendarContainerTarget.insertBefore(container, this.monthNavTarget);   
  }
  

  updateValue(selectedDates) {
    const matchingRange = this.ranges.find(range => {

      console.log(range);
      console.log(selectedDates);
      const rangeStartDate = this.parseDate(range.start_date);
      const rangeEndDate = this.parseDate(range.end_date);
  
      // Set selected dates to midnight for comparison
      const selectedStartDate = new Date(selectedDates[0]);
      selectedStartDate.setHours(0, 0, 0, 0);
      
      const selectedEndDate = new Date(selectedDates[1]);
      selectedEndDate.setHours(0, 0, 0, 0);
  
      return selectedDates.length === 2 &&
        selectedStartDate.getTime() === rangeStartDate.getTime() &&
        selectedEndDate.getTime() === rangeEndDate.getTime();
    });
  
    if (matchingRange) {
      this.inputTarget.value = matchingRange.name; // Set to matching range name
    } else if (selectedDates.length === 2) {
      const formattedStart = this.formatDate(selectedDates[0]);
      const formattedEnd = this.formatDate(selectedDates[1]);
      this.inputTarget.value = `${formattedStart} to ${formattedEnd}`; // Show selected dates
    } else {
      this.inputTarget.value = ""; // Clear if only one date is selected
    }
  }
  

  parseDate(dateString) {
    const [day, month, year] = dateString.split('/');
    const date = new Date(Date.parse(`${year}-${month}-${day}`)); // Rearrange to YYYY-MM-DD
    date.setHours(0, 0, 0, 0); // Set time to midnight
    return date;
  }

  change(selectedDates, dateStr, instance) {
    console.log(this.startDateTarget);
    if (selectedDates.length === 2) {
      // Set the values of the hidden fields using targets
      this.startDateTarget.value = selectedDates[0].toLocaleDateString('fr-FR', { year: 'numeric', month: 'numeric', day: 'numeric' });
      this.endDateTarget.value = selectedDates[1].toLocaleDateString('fr-FR', { year: 'numeric', month: 'numeric', day: 'numeric' });

      // Submit the parent form
      this.startDateTarget.form.submit();
    }
  }


  defineRanges() {
    const today = new Date();
    const quarters = [1, 4, 7, 10].map(month => new Date(today.getFullYear(), month - 1, 1));
    const currentQuarterStart = quarters[Math.floor((today.getMonth()) / 3)];
    const lastQuarterStart = new Date(currentQuarterStart);
    lastQuarterStart.setMonth(lastQuarterStart.getMonth() - 3);

    const RANGES = [
      { name: "Last 30 days", start_date: this.formatDate(new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000)), end_date: this.formatDate(today) },
      { name: "Current month", start_date: this.formatDate(new Date(today.getFullYear(), today.getMonth(), 1)), end_date: this.formatDate(today) },
      { name: "Last month", start_date: this.formatDate(new Date(today.getFullYear(), today.getMonth() - 1, 1)), end_date: this.formatDate(new Date(today.getFullYear(), today.getMonth(), 0)) },
      { name: "Current quarter", start_date: this.formatDate(currentQuarterStart), end_date: this.formatDate(new Date(currentQuarterStart.getFullYear(), currentQuarterStart.getMonth() + 3, 0)) },
      { name: "Last quarter", start_date: this.formatDate(lastQuarterStart), end_date: this.formatDate(new Date(lastQuarterStart.getFullYear(), lastQuarterStart.getMonth() + 3, 0)) },
      { name: "Current year", start_date: this.formatDate(new Date(today.getFullYear(), 0, 1)), end_date: this.formatDate(today) },
      { name: "Last year", start_date: this.formatDate(new Date(today.getFullYear() - 1, 0, 1)), end_date: this.formatDate(new Date(today.getFullYear() - 1, 11, 31)) },
    ];

    this.ranges = RANGES; // Store ranges for later use
  }

  formatDate(date) {
    return date.toLocaleDateString('fr-FR', { year: 'numeric', month: 'numeric', day: 'numeric' });
  }
}
