import { Dropdown } from "tailwindcss-stimulus-components";

export default class extends Dropdown {
  static targets = ["input", "hint", "menu", "button", "textInput"]

  connect() {
    super.connect();
    if (this.hasTextInputTarget) {
      this.textInputTarget.addEventListener('input', this.search.bind(this));
    }
    this.searchUrl = this.element.dataset.optionsUrl;
  }

  disconnect() {
    super.disconnect();
    if (this.hasTextInputTarget) {
      this.textInputTarget.removeEventListener('input', this.search.bind(this));
    }
  }
  

  search(event) {
    const query = this.textInputTarget.value;
    const multiselect = event.srcElement.dataset.multiselect
    const selected = event.srcElement.dataset.selected    

    const params = new URLSearchParams({ query });
    if (multiselect) params.append('multiselect', multiselect);
    if (selected) params.append('selected', selected);

    // if (!query.trim()) {
    //   // If the query is empty, do nothing or optionally clear the menu
    //   this.menuTarget.innerHTML = '';
    //   return;
    // }

    // Fetch the search results
    fetch(`${this.searchUrl}?${params.toString()}`, {
      headers: {
        'Accept': 'text/html'
      }
    })
    .then(response => response.text())
    .then(html => {
      // Update the menu with the new HTML
      this.menuTarget.innerHTML = html;
      this.menuTarget.classList.remove("hidden");
    })
    .catch(error => {
      console.error('Error fetching search results:', error);
    });
  }

  toggle(event) {
    super.toggle(event);
  }

  searchableToggle(event) {    
    // Open the dropdown as usual
    super.toggle(event);

    // Ensure we're working with the correct target
    if (this.hasTextInputTarget && this.menuTarget.classList.contains("hidden")) {
      console.log("Searchable dropdown is open, we're hiding stuff");
      // Clear the visible text input field
      this.textInputTarget.setAttribute('value', '');

      // Find the icon within the text input field using a specific data attribute
      const inputIcon = this.element.querySelector("[data-dropdown-input-icon]");

      // Remove the icon from the text input field only, if it exists
      if (inputIcon) {
        inputIcon.remove();
      }
    }

  }

  select(event) {
    
    const isSearchable = this.hasTextInputTarget && !this.hasButtonTarget;

    if (isSearchable) {
      const selectedOption = event.currentTarget;
      const label = selectedOption.querySelector("span").textContent.trim();
      const value = selectedOption.getAttribute("data-value");
    
      // Update the hidden input with the selected value
      this.inputTarget.value = value;
      // Dispatch a custom event to notify listeners
      const change_event = new Event('change', { bubbles: true });
      this.inputTarget.dispatchEvent(change_event);

    
      // Update the text input field
      this.textInputTarget.value = label;
      this.textInputTarget.setAttribute('value', label);
    
      // Find the icon within the selected option
      const icon = selectedOption.querySelector("img");
    
      // Clear any existing icon in the text input field
      const existingIcon = this.textInputTarget.parentElement.querySelector("[data-dropdown-input-icon]");
      if (existingIcon) {
        existingIcon.remove();
      }
    
      // Render the new icon in the text input field, if available
      if (icon) {
        // Clone the icon
        
        const iconClone = icon.cloneNode(true);           
        iconClone.classList.add("absolute", "top-1/2", "transform", "-translate-y-1/2", "left-3", "h-4", "w-4", "flex-shrink-0");
        iconClone.setAttribute("data-dropdown-input-icon", ""); 
    
        // Insert the icon into the parent container of the text input
        const parent = this.textInputTarget.parentElement;
        parent.appendChild(iconClone);
      }
    }
    
    else{
      const selectedOption = event.currentTarget;
      const value = selectedOption.getAttribute("data-value");

      // Update the hidden input with the selected value
      this.inputTarget.value = value;
      // Dispatch a custom event to notify listeners
      const change_event = new Event('change', { bubbles: true });
      this.inputTarget.dispatchEvent(change_event);


      // Find the widest option and set the button's width
      let widestWidth = 0;
      this.menuTarget.querySelectorAll("li").forEach(option => {
          const optionWidth = option.offsetWidth;
          if (optionWidth > widestWidth) {
              widestWidth = optionWidth;
          }
      });

      // Set the button's width to match the widest option
      //this.buttonTarget.style.width = `${widestWidth}px`;

      // Clear the hint target (the button's inner content)
      this.hintTarget.innerHTML = '';

      // Create a container to wrap the icon and text, and apply flexbox styling
      const wrapperDiv = document.createElement("div");
      wrapperDiv.classList.add("inline-flex", "items-center", "space-x-2", "truncate");  // Flexbox classes for proper alignment

      // Get the label and image from the selected option
      const label = selectedOption.querySelector("span").textContent.trim();
      const icon = selectedOption.querySelector("img");

      // If an icon exists, clone and append it to the wrapper
      if (icon) {
          const clonedIcon = icon.cloneNode(true);
          clonedIcon.classList.add("h-4");  // Keep the icon size consistent
          wrapperDiv.appendChild(clonedIcon);
      }

      // Create a span for the label text and append it to the wrapper
      const labelSpan = document.createElement("span");
      
      labelSpan.textContent = label;
      wrapperDiv.appendChild(labelSpan);

      // Append the wrapper to the hint target (button content)
      this.hintTarget.appendChild(wrapperDiv);

      // Close the dropdown
      this.menuTarget.classList.add("hidden");    
    }

    const submitOnSelect = this.element.dataset.submitOnSelect === 'true'; 

    if (submitOnSelect) {
        // Find the closest form and submit it
        this.inputTarget.closest('form').requestSubmit();
    }
  }

  selectMulti(event) {

    const isSearchable = this.hasTextInputTarget && !this.hasButtonTarget;

    const selectElement = this.inputTarget;

    if (isSearchable) {

      console.log(`isSearchable evaluated to ${isSearchable}`);

      const selectedOption = event.currentTarget;
      const value = selectedOption.getAttribute("data-value");
      
      // Toggle the selected state of the corresponding option
      const optionElement = selectElement.querySelector(`option[value="${value}"]`);
      if (optionElement) {
        optionElement.selected = !optionElement.selected;
        this.selectMultiUpdateUi(selectedOption, optionElement.selected);
      } else {
        // Option doesn't exist, create and prepend a new one

        const newOption = document.createElement("option");
        newOption.value = value;
        newOption.textContent = selectedOption.querySelector("span").textContent.trim();  // Get label from the selected option
        newOption.selected = true;  // Mark it as selected

        selectElement.prepend(newOption);
        this.selectMultiUpdateUi(selectedOption, newOption.selected);
      }      

    
      // Retrieve all selected values
      const selectedValues = Array.from(selectElement.selectedOptions).map(opt => opt.value);
    
      // Update the text input with selected values' labels
      if (selectedValues.length > 1) {
        this.textInputTarget.value = "Multiple selection";
        this.textInputTarget.setAttribute('value', "Multiple selection");
      } else if (selectedValues.length === 1) {
        const selectedName = this.menuTarget.querySelector(`li[data-value="${value}"] span`).textContent.trim();
        this.textInputTarget.value = selectedName;
        this.textInputTarget.setAttribute('value', selectedName);
      } else {
        this.textInputTarget.value = this.element.dataset.hint || "Select an option";
        this.textInputTarget.setAttribute('value', this.element.dataset.hint || "Select an option");
      }
    
      // Find the icon within the selected option (if it exists)
      const icon = selectedOption.querySelector("img");
    
      // Clear any existing icon in the text input field
      const existingIcon = this.textInputTarget.parentElement.querySelector("[data-dropdown-input-icon]");
      if (existingIcon) {
        existingIcon.remove();
      }
    
      // Render the new icon in the text input field, if available
      if (icon && selectedValues.length === 1) {
        const iconClone = icon.cloneNode(true);
        iconClone.classList.add("absolute", "top-1/2", "transform", "-translate-y-1/2", "left-3", "h-4", "w-4", "flex-shrink-0");
        iconClone.setAttribute("data-dropdown-input-icon", "");
    
        const parent = this.textInputTarget.parentElement;
        parent.appendChild(iconClone);
      }
    
      // Dispatch change event
      const changeEvent = new Event('change', { bubbles: true });
      selectElement.dispatchEvent(changeEvent);
    }
    else{    
      const selectedOption = event.currentTarget;
      const value = selectedOption.getAttribute("data-value");      
    
      // Toggle the selected state of the corresponding option
      const optionElement = selectElement.querySelector(`option[value="${value}"]`);
      if (optionElement) {
        optionElement.selected = !optionElement.selected;
    
        // Update the UI (e.g., highlight the option)
        selectedOption.classList.toggle("bg-blue-600", optionElement.selected);
        selectedOption.classList.toggle("text-white", optionElement.selected);
      }
    
      // Retrieve all selected values
      const selectedValues = Array.from(selectElement.selectedOptions).map(opt => opt.value);
      
      // Update the display text
      if (selectedValues.length > 1) {
        this.hintTarget.textContent = "Multiple selection";
      } else if (selectedValues.length === 1) {
        const selectedName = this.menuTarget.querySelector(`li[data-value="${value}"] span`).textContent.trim();
        this.hintTarget.textContent = selectedName;
      } else {
        this.hintTarget.textContent = this.element.dataset.hint || "Select an option";
      }
    
      // Dispatch change event
      const changeEvent = new Event('change', { bubbles: true });
      selectElement.dispatchEvent(changeEvent);
    }

  
    // Submit the form if required
    const submitOnSelect = this.element.dataset.submitOnSelect === 'true'; 
    if (submitOnSelect) {
      selectElement.closest('form').requestSubmit();
    }
  }

  selectMultiUpdateUi(selectedOption, isSelected) {
    // Update the UI (e.g., highlight the option)
    selectedOption.classList.toggle("bg-blue-600", isSelected);
    selectedOption.classList.toggle("!text-white", isSelected);
  
    // Add or remove the checkmark icon
    if (isSelected) {
      // Check if the checkmark already exists
      let checkmark = selectedOption.querySelector('.checkmark');
      if (!checkmark) {
        // Create the checkmark element if it doesn't exist
        const checkmarkSpan = document.createElement('span');
        checkmarkSpan.classList.add("absolute", "inset-y-0", "right-0", "flex", "items-center", "pr-4", "checkmark"); // Adding checkmark class to identify it later
  
        // Create the SVG element
        checkmarkSpan.innerHTML = `
          <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
          </svg>
        `;
        // Append the checkmark to the selected option
        selectedOption.appendChild(checkmarkSpan);
      }
    } else {
      // Remove the checkmark if the option is deselected
      const checkmark = selectedOption.querySelector('.checkmark');
      if (checkmark) {
        checkmark.remove();
      }
    }
  }
  
  


  hide(event) {
    super.hide(event);
  }
}
