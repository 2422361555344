import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect(){
  }
  preselected(event){
    console.log(event.target.dataset.startDate)
      var url = window.location.href
      url = updateUrlParameter(url, "end_date", event.target.dataset.endDate)
      url = updateUrlParameter(url, "start_date", event.target.dataset.startDate)
      console.log(url)
      window.location.href = url;

    // Add / Update a key-value pair in the URL query parameters
    function updateUrlParameter(uri, key, value) {
      // remove the hash part before operating on the uri
      var i = uri.indexOf('#');
      var hash = i === -1 ? ''  : uri.substr(i);
           uri = i === -1 ? uri : uri.substr(0, i);

      var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
      var separator = uri.indexOf('?') !== -1 ? "&" : "?";
      if (uri.match(re)) {
          uri = uri.replace(re, '$1' + key + "=" + value + '$2');
      } else {
          uri = uri + separator + key + "=" + value;
      }
      return uri + hash;  // finally append the hash as well
    }
  }
}
