// app/javascript/controllers/charts_controller.js
import { Controller } from "@hotwired/stimulus"
import { Chart, registerables } from 'chart.js'
import { patch } from "@rails/request.js"
Chart.register(...registerables)

export default class extends Controller {
  static values = {
    endpoint: String,
    datasets: Array,
    labels: Array
  }

  static targets = ["loadingState", "tableContainer", "chartContainer", "typeToggle", "typeLabel", "chartLegend"]
  

  connect() {
    this.renderChart();

    this.hideLoadingState();    

    // Listen for Turbo events
    this.element.addEventListener("turbo:before-fetch-request", this.showLoadingState.bind(this));
    this.element.addEventListener("turbo:frame-load", this.hideLoadingState.bind(this));
  }  

  showLoadingState() {
    console.log("Show loading state");
    this.loadingStateTarget.classList.remove("hidden");
    this.tableContainerTarget.classList.add("hidden");
    this.chartContainerTarget.classList.add("hidden");
    this.chartLegentTarget.classList.add("hidden");
  }

  // Hide loading state and show the correct view (chart or table)
  hideLoadingState() {
    console.log("Hide loading state");
    this.loadingStateTarget.classList.add("hidden");
    this.updateView(); // Show the right view based on the toggle state
  }

  // Toggle the view between chart and table
  toggleView(event) {
    console.log("Toggle view clicked");
    this.updateView();
  }

  // Update the view and the label based on the state of the toggle
  updateView() {
    if (this.typeToggleTarget.checked) {
      this.chartContainerTarget.classList.remove("hidden");
      this.chartLegendTarget.classList.remove("hidden");
      this.tableContainerTarget.classList.add("hidden");
      this.typeLabelTarget.textContent = "Chart";
    } else {
      this.chartContainerTarget.classList.add("hidden");
      this.chartLegendTarget.classList.add("hidden");
      this.tableContainerTarget.classList.remove("hidden");
      this.typeLabelTarget.textContent = "Table";
    }
  }

  toggleDataset(event) {
    const checkbox = event.target;
    const datasetIndex = checkbox.dataset.index;
  
    if (this.chart && this.chart.data.datasets[datasetIndex]) {
      this.chart.data.datasets[datasetIndex].hidden = !checkbox.checked;
      this.chart.update();
    }
  }  

  

  renderChart() {
    const ctx = this.element.querySelector('#chartCanvas').getContext('2d')
    
    const scales = {}    

    // Dynamically configure axes based on dataset yAxisID values
    this.datasetsValue.forEach((dataset, index) => {
      // Configure Y axis if it's not already set
      if (!scales[dataset.yAxisID]) {
        scales[dataset.yAxisID] = this.configureYAxis(dataset.yAxisID, index);
      }
  
      // Configure X axis if it's not already set
      if (dataset.xAxisID && !scales[dataset.xAxisID]) {
        scales[dataset.xAxisID] = this.configureXAxis(dataset.xAxisID);
      }
    });  

    this.chart = new Chart(ctx, {
      type: 'bar', // Or 'line', depending on your default chart type
      data: {
        labels: this.labelsValue,
        datasets: this.datasetsValue
      },
      options: {
        responsive: true,
        scales: scales,
        interaction: {
          mode: 'index',      // Show tooltip for all datasets at the hovered index
          intersect: false    // Tooltip activates when hovering anywhere near the index
        },
        plugins: {
          legend: {
            display: false,
            position: 'top',
          },
          title: {
            display: false,
            text: 'Chart Title'
          },
          tooltip: {
            callbacks: {
              title: function(context) {
                return `${context[0].label}`
              },
              label: function(context) {
                let label = context.dataset.label || ''
                if (label) {
                  label += ': '
                }
              
                // Determine the desired format based on dataset properties
                let value = context.parsed.y
                if (context.dataset.format) {
                  const [type, decimals] = context.dataset.format.split(':')
                  if (type === 'integer') {
                    value = Math.round(value)
                  } else if (type === 'float') {
                    const decimalPlaces = decimals ? parseInt(decimals) : 2
                    value = value.toFixed(decimalPlaces)
                  }
                } else {
                  // Default formatting
                  value = value.toFixed(2)
                }
              
                label += value !== null ? value : 'No Data'
                return label
              },                          
            },
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            titleColor: '#ffffff',
            bodyColor: '#ffffff',
            footerColor: '#ffffff',
            titleFont: {
              size: 16,
              weight: 'bold'
            },
            bodyFont: {
              size: 14
            },
            padding: 10,
            cornerRadius: 8,
          }
        }
      }
    })
  }

  configureXAxis(axisID) {
    switch(axisID) {
      case 'x-axis-stacked-count':  // X-axis stacked config
        return {
          type: 'category',
          display: true,
          stacked: true,  // Enable stacking for X-axis          
          title: {
            display: true,
            text: 'Time Period'
          },
          grid: {
            drawOnChartArea: true
          }
        };
      default:
        return {
          type: 'category',
          display: true,
          stacked: false,
          title: {
            display: true,
            text: 'Default X Axis'
          }
        };
    }
  }
  

  configureYAxis(axisID, index) {
    const position = index === 0 ? 'left' : 'right'; // Primary axis on the left, secondary on the right

    switch(axisID) {
      case 'y-axis-count':
        return {
          type: 'linear',
          display: true,
          position: position,
          title: {
            display: true,
            text: 'Count'
          }
        }
      case 'y-axis-rating':
        return {
          type: 'linear',
          display: true,
          position: position,
          title: {
            display: true,
            text: 'Rating'
          },
          grid: {
            drawOnChartArea: false
          }
        }
      case 'y-axis-cumulative':
        return {
          type: 'linear',
          display: true,
          position: position,
          title: {
            display: true,
            text: 'Cumulative'
          },
          grid: {
            drawOnChartArea: false
          }
        }
      case 'y-axis-rank':
        return {
          type: 'linear',
          display: true,
          position: position,
          title: {
            display: true,
            text: 'Rank'
          },
          grid: {
            drawOnChartArea: false
          },
          min: 1,
          reverse: true, // Invert the axis so lower ranks are higher
          ticks: {
            beginAtZero: false, // Don't start at zero since rank usually starts from 1
            stepSize: 1,            
          }
        }
      case 'y-axis-grid-scores':
        return {
          type: 'linear',
          display: true,
          position: position,
          title: {
            display: true,
            text: 'Grid Scores'
          },
          grid: {
            drawOnChartArea: false
          }
        }
      case 'y-axis-cumulative-count':
        return {
          type: 'linear',
          display: true,
          position: position,
          title: {
            display: true,
            text: 'Cumulative Count'
          },
          grid: {
            drawOnChartArea: false
          }
        }
      case 'y-axis-stacked-count':
        return {
          type: 'linear',
          display: true,
          position: position,
          stacked: true,  // Make the bars stacked
          ticks: {
            beginAtZero: true,
            stepSize: 1
          },
          title: {
            display: true,
            text: 'Cumulative Count'
          },
          grid: {
            drawOnChartArea: true
          }
        }
      default:
        return {
          type: 'linear',
          display: true,
          position: 'left',
          title: {
            display: true,
            text: 'Primary Axis'
          }
        }
    }
  }
}
