// app/javascript/controllers/autosize_controller.js

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    console.log("Connected to input resize controller");
    this.resize(); // Ensure it resizes on load
  }

  resize() {
    const input = this.inputTarget;
    input.style.width = "1px"; // Temporarily set width to a small value to calculate new width correctly
    input.style.width = `${input.scrollWidth}px`; // Set width to the scroll width of the input
  }
}
