import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  save_and_activate(){
    document.querySelector("input#flow_activate").value = true
    document.querySelector("form#flow").submit()
  }

  update_form(e){    
    let form = e.target.form
    if (e.target.value == "Daily recap"){
      form.querySelector("#notification_time").classList.remove("hidden")
      form.querySelector("#flow_day_of_week").classList.add("hidden")              
      form.querySelector("#filters").classList.add("hidden")
    }
    else if (e.target.value == "Weekly recap"){
      form.querySelector("#notification_time").classList.remove("hidden")
      form.querySelector("#flow_day_of_week").classList.remove("hidden")      
      form.querySelector("#filters").classList.add("hidden")
      form.querySelector("#template_field").classList.add("hidden")
    }
    else{
      form.querySelector("#notification_time").classList.add("hidden")      
      form.querySelector("#filters").classList.remove("hidden")
      form.querySelector("#template_field").classList.remove("hidden")
    }
  }

  update_channel(e){
    fetch(`/channels${e.target.value}.json`, {
      headers: {
        'Accept': 'application/json'
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        if(data.provider == "slack"){
          document.querySelector("#template_field").classList.remove("hidden")
        }
        else{
          document.querySelector("#template_field").classList.add("hidden")
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
    }   
}
