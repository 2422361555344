// app/javascript/controllers/select_all_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox"];

  connect(){
    console.log(checkboxTargets);
  }

  toggleAll(event) {
    const isChecked = event.currentTarget.checked;
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = isChecked;
    });
  }
}
